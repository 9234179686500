<template>
    <div>
        <div
            v-if="loading"
            class="text-center mt-3"
        >
            {{ $t('app.content_loading') }}
        </div>
        <div
            v-for="(period, index) in periods"
            :key="index"
        >
            <TableAccordionItem
                :period="getPeriodDate(period)"
                :balance="currencyFormatter(period.balance)"
                :is-active="period.active"
            >
                <div class="block lg:hidden">
                    <div
                        v-for="(item, index) in period.items"
                        :key="index"
                        class="mt-3"
                    >
                        <TableMobileItem>
                            <template #main>
                                <p>{{ capitalize(item.name) }}</p>
                                <p>{{ currencyFormatter(item.paidAmount || item.chargeAmount) }}</p>
                            </template>
                            <template #additional>
                                <p>#{{ item.refNumber }}</p>
                                <p>{{ dateFormatter(item.postDate) }}</p>
                            </template>
                        </TableMobileItem>
                    </div>
                </div>

                <TableDesktop class="hidden lg:block">
                    <template #colgroup>
                        <col width="20%" />
                        <col width="20%" />
                        <col width="10%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="0%" />
                        <col width="10%" />
                    </template>
                    <template #headers>
                        <th>{{ $t('payments.list_columns.name') }}</th>
                        <th>{{ $t('payments.list_columns.payer') }}</th>
                        <th>{{ $t('payments.list_columns.reference_no') }}</th>
                        <th>{{ $t('payments.list_columns.date_posted') }}</th>
                        <th>{{ $t('payments.list_columns.date_due') }}</th>
                        <th>{{ $t('payments.list_columns.amount') }}</th>
                        <th>{{ $t('payments.list_columns.balance') }}</th>
                    </template>
                    <template #content>
                        <tr
                            v-for="(item, index) in period.items"
                            :key="index"
                            :class="{ 'font-bold': item.name === 'payment' }"
                        >
                            <td data-test="name">{{ capitalize(item.name) }}</td>
                            <td data-test="payer">{{ item.payer }}</td>
                            <td data-test="refNumber">{{ item.refNumber }}</td>
                            <td data-test="postDate">{{ dateFormatter(item.postDate) }}</td>
                            <td data-test="due">{{ dateFormatter(item.due) }}</td>
                            <td data-test="amount">{{ currencyFormatter(item.paidAmount || item.chargeAmount) }}</td>
                            <td data-test="balance">{{ currencyFormatter(item.balance) }}</td>
                        </tr>
                    </template>
                </TableDesktop>
            </TableAccordionItem>
        </div>
    </div>
</template>

<script>
import TableMobileItem from '@/components/payments/components/TableMobileItem';
import TableDesktop from '@/components/payments/components/TableDesktop';
import TableAccordionItem from '@/components/payments/components/TableAccordionItem';
import AuthMixin from '@/mixins/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import PayerMixin from '@/mixins/PayerMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { dateFormatter, getPeriodDate } from '@/utils/Date';
import { currencyFormatter } from '@/utils/helpers';
import { getAccountingErrorMessage } from '@/utils/accounting';
import { capitalize } from 'lodash-es';
import { mapGetters } from 'vuex';

export default {
    name: 'AccountSummary',

    components: {
        TableAccordionItem,
        TableMobileItem,
        TableDesktop,
    },

    mixins: [AuthMixin, NotifyMixin, PayerMixin, AccountingQueryManager],

    props: {
        /**
         * Avaliable totals for certain period
         * with period/balance information
         */
        totals: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            periods: [],
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
            chargeTotals: 'accounting/chargeTotals',
        }),
    },

    watch: {
        'currentProfile.occupancyId'(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.getOccupancy();
                this.getPeriods();
            }
        },
    },

    async created() {
        if (this.currentProfile?.occupancyId) {
            await this.getOccupancy();
            await this.getPeriods();
        }
    },

    methods: {
        dateFormatter,
        getPeriodDate,
        capitalize,
        currencyFormatter,

        addPreviousMonthBalance(transactions) {
            const name = this.$t('payments.previous_months_balance');
            const lastTransaction = transactions[transactions.length - 1];
            let balance = 0;

            lastTransaction.name === 'payment'
                ? (balance = lastTransaction.balance - lastTransaction.paidAmount)
                : (balance = lastTransaction.balance + lastTransaction.chargeAmount);

            const previousMonthBalance = {
                name,
                balance,
            };
            return [...transactions, previousMonthBalance];
        },

        async getReceivableBalances(yearMonth) {
            try {
                return await this.queryHandler('getReceivableBalances', 'resident', {
                    accountId: this.currentProfile.occupancyId,
                    yearMonth,
                });
            } catch (error) {
                this.notifyError(getAccountingErrorMessage(error));
            }
        },

        async getPeriods() {
            this.loading = true;
            try {
                this.periods = await Promise.all(
                    this.totals.map(async (total, i) => {
                        const transactions = await this.getReceivableBalances(total.period);
                        const items = this.addPayer(transactions);

                        return {
                            ...total,
                            items,
                            active: i === 0,
                        };
                    })
                );
            } catch (error) {
                this.notifyError(getAccountingErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
