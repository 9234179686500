import { i18n } from '@/i18n';
import { ACCOUNTING_ERROR_CODES_MAP } from '@/utils/constants/accounting';

export const getAccountingErrorMessage = response => {
    if (response) {
        if (response.data?.errors?.length > 0) {
            const accountingError = response.data.errors[0];
            const predefinedMessage = ACCOUNTING_ERROR_CODES_MAP[accountingError.errorCode];
            return predefinedMessage ?? accountingError.message;
        }
        return response.message;
    }
    return i18n.global.t('accounting.error_messages.default');
};
