<template>
    <div
        class="accordion"
        data-test="table-accordion-item"
    >
        <div
            class="accordion-item"
            :class="{ 'is-active': active }"
        >
            <button
                class="accordion-item-header w-full"
                @click="toggle"
            >
                <div
                    class="uppercase font-bold"
                    data-test="period"
                >
                    {{ period }}
                </div>
                <div
                    class="font-bold"
                    data-test="balance"
                >
                    {{ balance }}
                </div>
            </button>
            <transition
                name="accordion-details"
                @enter="startTransition"
                @after-enter="endTransition"
                @before-leave="startTransition"
                @after-leave="endTransition"
            >
                <div
                    v-if="active"
                    class="accordion-item-details"
                >
                    <slot />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableAccordionItem',

    props: {
        /**
         * Time period of transactions in 'month' + 'year' format
         */
        period: {
            type: String,
            default: '',
        },

        /**
         * Money balance for certain period
         */
        balance: {
            type: String,
            default: '',
        },

        /**
         * Default visibility state for accordion-item
         */
        isActive: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            active: this.isActive,
        };
    },

    methods: {
        startTransition(el) {
            el.style.height = el.scrollHeight + 'px';
        },

        endTransition(el) {
            el.style.height = '';
        },

        toggle() {
            this.active = !this.active;
        },
    },
};
</script>

<style scoped>
.accordion-item-header {
    @apply flex flex-wrap items-center cursor-pointer rounded-md justify-between text-purple-600 font-sofia text-md2 bg-purple-100 hover:bg-purple-200 border-purple-100 hover:border-purple-200 transition duration-100 ease-in select-none py-2 px-3 mt-5 mb-1;
    border-width: 1px;
}

.accordion-item-details {
    @apply overflow-hidden;
}

.accordion-item.is-active .accordion-item-header {
    @apply border-purple-300;
}

.accordion-details-enter-active,
.accordion-details-leave-active {
    will-change: height;
    transition: height 0.2s ease-in;
}

.accordion-details-enter,
.accordion-details-leave-to {
    height: 0 !important;
}
</style>
